"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    mounted() {
        this.handleEvent("room_full", () => {
            this.el.classList.add("show");
            setTimeout(() => {
                this.el.classList.remove("show");
            }, 3000);
        });
    }
};
