"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
let wherebyFullscreen = false;
function gradualySetVolume(volume, modifier = 0.4, itteration = 10) {
    let windowYT = window;
    windowYT.ytPlayer.getVolume().then(curvolume => {
        if (volume != curvolume) {
            if (volume > curvolume) {
                let newvolume = curvolume + (volume * (modifier / itteration));
                newvolume = Math.ceil(newvolume);
                if (newvolume > volume)
                    newvolume = volume;
                windowYT.ytPlayer.setVolume(newvolume);
            }
            else {
                let newvolume = curvolume * modifier;
                newvolume = Math.floor(newvolume);
                console.log(newvolume);
                windowYT.ytPlayer.setVolume(newvolume);
            }
            setTimeout(() => {
                gradualySetVolume(volume, modifier, itteration - 1);
            }, 100);
        }
    });
}
exports.default = {
    mounted() {
        var _a;
        let videoElement = document.getElementById("player");
        let whereby = document.getElementById("whereby-video");
        let wherebyFullscreenElement = document.getElementById("fullscreen-whereby");
        let wherebyFullscreenTextElement = wherebyFullscreenElement.firstElementChild;
        let mainElement = document.getElementById("some-junk");
        let chatRoomOpenButton = document.getElementById("open-room-chat");
        let chatRoomOpenButtonWhereby = document.getElementById("open-room-chat-whereby");
        wherebyFullscreenElement.addEventListener("click", () => {
            if (wherebyFullscreen) {
                videoElement.style.setProperty("opacity", "1");
                videoElement.style.removeProperty("visibility");
                let volume = Number.parseInt(window.localStorage.getItem("audio-volume"));
                let volumeMuted = Number.parseInt(window.localStorage.getItem("audio-muted")) == 1;
                wherebyFullscreenTextElement.style.setProperty("visibility", "hidden");
                whereby.classList.remove("large");
                mainElement.style.removeProperty("width");
                wherebyFullscreenElement.style.setProperty("right", "calc(100% - 24px)");
                wherebyFullscreenElement.style.setProperty("transform", "rotate(0deg)");
                wherebyFullscreenTextElement.style.setProperty("transform", "rotate(0deg)");
                if (!volumeMuted)
                    gradualySetVolume(volume, 0.3);
                setTimeout(() => {
                    wherebyFullscreenTextElement.style.removeProperty("visibility");
                }, 400);
            }
            else {
                gradualySetVolume(0, 0.6);
                videoElement.style.setProperty("opacity", "0");
                wherebyFullscreenTextElement.style.setProperty("visibility", "hidden");
                mainElement.style.setProperty("width", "100%", "important");
                whereby.classList.add("large");
                wherebyFullscreenElement.style.setProperty("right", "0");
                wherebyFullscreenElement.style.setProperty("transform", "rotate(180deg)");
                wherebyFullscreenTextElement.style.setProperty("transform", "rotate(-180deg)");
                setTimeout(() => {
                    wherebyFullscreenTextElement.style.removeProperty("visibility");
                }, 200);
                setTimeout(() => {
                    videoElement.style.setProperty("visibility", "hidden");
                }, 400);
            }
            wherebyFullscreen = !wherebyFullscreen;
        });
        (_a = document.getElementById("room-chat-hide-button")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", () => {
            whereby.style.setProperty("height", "100%");
            chatRoomOpenButtonWhereby.style.setProperty("display", "block");
        });
        chatRoomOpenButtonWhereby.addEventListener("click", () => {
            videoElement.style.removeProperty("height");
            chatRoomOpenButton.style.setProperty("display", "none");
            whereby.style.removeProperty("height");
            chatRoomOpenButtonWhereby.style.setProperty("display", "none");
        });
    }
};
