"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    mounted() {
        let parentElement = this.el;
        this.handleEvent("whereby_loaded", () => {
            parentElement.classList.add("whereby_loaded");
        });
        this.handleEvent("whereby_unloaded", () => {
            parentElement.classList.remove("whereby_loaded");
        });
    }
};
