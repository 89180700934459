"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var value_regex = /data-phx-value-/i;
exports.default = {
    mounted() {
        let parent = this;
        let container = this.el;
        this.el.addEventListener("click", () => {
            let values = container.getAttributeNames().filter(t => value_regex.test(t));
            let data = {};
            for (var i in values) {
                data[i] = document.querySelector(`[data-phx-view=${parent.viewName}] ${container.getAttribute(values[i])}`).value;
            }
            let event = container.getAttribute("data-phx-route");
            parent.pushEvent(event, data);
        });
    }
};
